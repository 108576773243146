var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.title || _vm.images.length)?_c('div',{staticClass:"c-block-list-image-gallery-custom w-full",attrs:{"id":_vm.$vnode.key}},[(_vm.title && _vm.includeInToc)?_c('LongReadTarget',{attrs:{"container-id":_vm.$vnode.key,"title":_vm.title}}):_vm._e(),_vm._v(" "),(_vm.title)?_c('BaseH2',{staticClass:"mb-2xl",domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),(_vm.images && _vm.images.length)?_c('div',{staticClass:"flex space-x-layout-gutter"},[_c('div',{class:['flex flex-col gap-y-xl', 'flex-1 >=1024:flex-auto']},[(_vm.images[0])?_c('button',{class:[
					'c-block-list-image-gallery-custom__preview-image',
					'w-full >=1024:w-4/12col',
				],attrs:{"aria-label":"åben billede 1 i stor størrelse"},on:{"click":() => _vm.openLightbox(0)}},[_c('UmbracoImageExt',{key:`preview-image-0-${_vm.mobile}`,staticClass:"w-full",attrs:{"widths":[160, 240, 304, 420, 640],"alt":_vm.images[0].altText,"source-url":_vm.images[0].cropUrl,"source-width":_vm.images[0].width,"source-height":_vm.images[0].height,"aspect-ratio":_vm.mobile ? 164 / 236 : 1}}),_vm._v(" "),_c('span',{staticClass:"c-block-list-image-gallery-custom__preview-icon"},[_c('SvgPlus')],1)],1):_vm._e(),_vm._v(" "),(_vm.images.length > 3)?_c('button',{staticClass:"flex items-center space-x-12 group",on:{"click":() => _vm.openLightbox(0)}},[_c('span',{class:[
						'flex justify-center items-center',
						'w-40 h-40 >=1024:w-32 >=1024:h-32',
						'rounded-full bg-black',
					]},[_c('SvgFullScreen',{staticClass:"w-18 text-white"})],1),_vm._v(" "),_c('span',{class:[
						'text-button font-darker-grotesque font-medium',
						'duration-500 ease-smooth-out',
						'transform group-hover:translate-x-8',
						'mb-2',
					],domProps:{"textContent":_vm._s('Se alle billeder')}})]):_vm._e()]),_vm._v(" "),_c('div',{class:[
				'flex flex-col gap-y-layout-gutter',
				'flex-1 >=1024:flex-auto',
			]},[(_vm.images[1])?_c('button',{class:[
					'c-block-list-image-gallery-custom__preview-image',
					'w-3/4 >=1024:w-3/12col',
				],attrs:{"aria-label":"åben billede 2 i stor størrelse"},on:{"click":() => _vm.openLightbox(1)}},[_c('UmbracoImageExt',{key:`preview-image-1-${_vm.mobile}`,staticClass:"w-full",attrs:{"widths":[160, 240, 304, 420, 640],"alt":_vm.images[1].altText,"source-url":_vm.images[1].cropUrl,"source-width":_vm.images[1].width,"source-height":_vm.images[1].height,"aspect-ratio":_vm.mobile ? 1 : 304 / 218}}),_vm._v(" "),_c('span',{staticClass:"c-block-list-image-gallery-custom__preview-icon"},[_c('SvgPlus')],1)],1):_vm._e(),_vm._v(" "),(_vm.images[2])?_c('button',{class:[
					'c-block-list-image-gallery-custom__preview-image',
					'w-full >=1024:w-4/12col',
				],attrs:{"aria-label":"åben billede 3 i stor størrelse"},on:{"click":() => _vm.openLightbox(2)}},[_c('UmbracoImageExt',{key:`preview-image-2-${_vm.mobile}`,staticClass:"w-full",attrs:{"widths":[160, 240, 304, 417, 640],"alt":_vm.images[2].altText,"source-url":_vm.images[2].cropUrl,"source-width":_vm.images[2].width,"source-height":_vm.images[2].height,"aspect-ratio":_vm.mobile ? 163 / 157 : 417 / 242}}),_vm._v(" "),_c('span',{staticClass:"c-block-list-image-gallery-custom__preview-icon"},[_c('SvgPlus')],1)],1):_vm._e()])]):_vm._e(),_vm._v(" "),_c('Portal',{attrs:{"to":"overlay"}},[_c('Transition',{attrs:{"duration":2000,"name":"t-block-list-images-gallery-custom__lightbox"}},[(_vm.overlayActive)?_c('div',{key:`ImageGallery${_vm._uid}`,class:[
					'c-block-list-image-gallery-custom__lightbox',
					'relative bg-white h-full',
				]},[_c('div',{class:[
						'relative max-w-layout-max px-layout-margin',
						'mx-auto h-full',
					]},[_c('TransitionExt',{attrs:{"name":"block-list-images-gallery-custom__lightbox-item","duration":500,"mode":"out-in"}},[_c('figure',{key:`lightbox-item-${_vm.index}`,ref:"lightboxContainer",class:[
								'c-block-list-image-gallery-custom__lightbox-item',
								'relative flex flex-shrink-0 justify-center items-center',
								'w-full h-full snap-center',
							]},[_c('UmbracoImageExt',{staticClass:"relative z-10",style:({
									width: `${_vm.renderedImages[_vm.index].renderedWidth}px`,
									height: `${_vm.renderedImages[_vm.index].renderedHeight}px`,
								}),attrs:{"aria-describedby":`lightbox-description-${_vm.index}`,"alt":_vm.images[_vm.index].altText,"credit":_vm.images[_vm.index].photoCredits,"widths":[320, 480, 768, 1024, 1440, 1920],"source-width":_vm.images[_vm.index].width,"source-height":_vm.images[_vm.index].height,"source-url":_vm.images[_vm.index].cropUrl ||
									_vm.images[_vm.index].url}}),_vm._v(" "),_c('div',{class:[
									'c-block-list-image-gallery-custom__lightbox-text',
									'absolute bottom-0 text-center text-body h-7xl w-full',
									'flex items-center justify-center gap-x-md',
								]},[_c('div',{class:[
										'text-primary-button',
										'font-semibold',
									],domProps:{"textContent":_vm._s(
										`${_vm.index + 1} / ${
											_vm.images && _vm.images.length
										}`
									)}}),_vm._v(" "),_c('figcaption',{attrs:{"id":`lightbox-description-${_vm.index}`},domProps:{"textContent":_vm._s(_vm.images[_vm.index].altText)}})])],1)]),_vm._v(" "),_c('BaseButton',{class:[
							'c-block-list-image-gallery-custom__lightbox-button',
							'absolute top-1/2 left-6 >=1024:left-layout-margin w-40 h-40 z-10',
							'transform -translate-y-1/2',
							'border-2 border-white border-opacity-50',
						],attrs:{"aria-disabled":_vm.index === 0,"aria-label":"Se forrige billede","dark":""},on:{"click":_vm.gotoPrevious},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgCaret',{staticClass:"transform rotate-90 -translate-x-2"})]},proxy:true}],null,false,1402005718)}),_vm._v(" "),_c('BaseButton',{class:[
							'c-block-list-image-gallery-custom__lightbox-button',
							'absolute top-1/2 right-6 >=1024:right-layout-margin w-40 h-40 z-10',
							'transform -translate-y-1/2',
							'border-2 border-white border-opacity-50',
						],attrs:{"aria-disabled":_vm.images ? _vm.index === _vm.images.length - 1 : true,"aria-label":"Se næste billede","dark":""},on:{"click":_vm.gotoNext},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgCaret',{staticClass:"transform -rotate-90 translate-x-2"})]},proxy:true}],null,false,588707062)}),_vm._v(" "),_c('BaseButton',{staticClass:"absolute top-lg right-layout-margin",attrs:{"light":""},on:{"click":_vm.closeLightbox},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{staticClass:"flex items-center gap-x-xs"},[_c('span',{staticClass:"font-semibold"},[_vm._v("Luk")]),_vm._v(" "),_c('SvgClose',{staticClass:"mt-2 w-xs h-xs text-text"})],1)]},proxy:true}],null,false,635386097)})],1)]):_vm._e()])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }