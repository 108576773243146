<template>
	<div
		v-if="title || images.length"
		:id="$vnode.key"
		class="c-block-list-image-gallery-custom w-full"
	>
		<LongReadTarget
			v-if="title && includeInToc"
			:container-id="$vnode.key"
			:title="title"
		/>

		<BaseH2 v-if="title" class="mb-2xl" v-text="title" />

		<div v-if="images && images.length" class="flex space-x-layout-gutter">
			<div :class="['flex flex-col gap-y-xl', 'flex-1 >=1024:flex-auto']">
				<button
					v-if="images[0]"
					:class="[
						'c-block-list-image-gallery-custom__preview-image',
						'w-full >=1024:w-4/12col',
					]"
					aria-label="åben billede 1 i stor størrelse"
					@click="() => openLightbox(0)"
				>
					<UmbracoImageExt
						:key="`preview-image-0-${mobile}`"
						class="w-full"
						:widths="[160, 240, 304, 420, 640]"
						:alt="images[0].altText"
						:source-url="images[0].cropUrl"
						:source-width="images[0].width"
						:source-height="images[0].height"
						:aspect-ratio="mobile ? 164 / 236 : 1"
					/>

					<span
						class="c-block-list-image-gallery-custom__preview-icon"
					>
						<SvgPlus />
					</span>
				</button>

				<button
					v-if="images.length > 3"
					class="flex items-center space-x-12 group"
					@click="() => openLightbox(0)"
				>
					<span
						:class="[
							'flex justify-center items-center',
							'w-40 h-40 >=1024:w-32 >=1024:h-32',
							'rounded-full bg-black',
						]"
					>
						<SvgFullScreen class="w-18 text-white" />
					</span>

					<span
						:class="[
							'text-button font-darker-grotesque font-medium',
							'duration-500 ease-smooth-out',
							'transform group-hover:translate-x-8',
							'mb-2',
						]"
						v-text="'Se alle billeder'"
					></span>
				</button>
			</div>

			<div
				:class="[
					'flex flex-col gap-y-layout-gutter',
					'flex-1 >=1024:flex-auto',
				]"
			>
				<button
					v-if="images[1]"
					:class="[
						'c-block-list-image-gallery-custom__preview-image',
						'w-3/4 >=1024:w-3/12col',
					]"
					aria-label="åben billede 2 i stor størrelse"
					@click="() => openLightbox(1)"
				>
					<UmbracoImageExt
						:key="`preview-image-1-${mobile}`"
						class="w-full"
						:widths="[160, 240, 304, 420, 640]"
						:alt="images[1].altText"
						:source-url="images[1].cropUrl"
						:source-width="images[1].width"
						:source-height="images[1].height"
						:aspect-ratio="mobile ? 1 : 304 / 218"
					/>

					<span
						class="c-block-list-image-gallery-custom__preview-icon"
					>
						<SvgPlus />
					</span>
				</button>

				<button
					v-if="images[2]"
					:class="[
						'c-block-list-image-gallery-custom__preview-image',
						'w-full >=1024:w-4/12col',
					]"
					aria-label="åben billede 3 i stor størrelse"
					@click="() => openLightbox(2)"
				>
					<UmbracoImageExt
						:key="`preview-image-2-${mobile}`"
						class="w-full"
						:widths="[160, 240, 304, 417, 640]"
						:alt="images[2].altText"
						:source-url="images[2].cropUrl"
						:source-width="images[2].width"
						:source-height="images[2].height"
						:aspect-ratio="mobile ? 163 / 157 : 417 / 242"
					/>

					<span
						class="c-block-list-image-gallery-custom__preview-icon"
					>
						<SvgPlus />
					</span>
				</button>
			</div>
		</div>

		<!-- Light box (Overlay) -->
		<Portal to="overlay">
			<Transition
				:duration="2000"
				name="t-block-list-images-gallery-custom__lightbox"
			>
				<div
					v-if="overlayActive"
					:key="`ImageGallery${_uid}`"
					:class="[
						'c-block-list-image-gallery-custom__lightbox',
						'relative bg-white h-full',
					]"
				>
					<div
						:class="[
							'relative max-w-layout-max px-layout-margin',
							'mx-auto h-full',
						]"
					>
						<TransitionExt
							name="block-list-images-gallery-custom__lightbox-item"
							:duration="500"
							mode="out-in"
						>
							<figure
								:key="`lightbox-item-${index}`"
								ref="lightboxContainer"
								:class="[
									'c-block-list-image-gallery-custom__lightbox-item',
									'relative flex flex-shrink-0 justify-center items-center',
									'w-full h-full snap-center',
								]"
							>
								<!-- Image -->
								<UmbracoImageExt
									:aria-describedby="`lightbox-description-${index}`"
									:alt="images[index].altText"
									:credit="images[index].photoCredits"
									:widths="[320, 480, 768, 1024, 1440, 1920]"
									:source-width="images[index].width"
									:source-height="images[index].height"
									:source-url="
										images[index].cropUrl ||
										images[index].url
									"
									:style="{
										width: `${renderedImages[index].renderedWidth}px`,
										height: `${renderedImages[index].renderedHeight}px`,
									}"
									class="relative z-10"
								/>

								<!-- Image Text -->
								<div
									:class="[
										'c-block-list-image-gallery-custom__lightbox-text',
										'absolute bottom-0 text-center text-body h-7xl w-full',
										'flex items-center justify-center gap-x-md',
									]"
								>
									<div
										:class="[
											'text-primary-button',
											'font-semibold',
										]"
										v-text="
											`${index + 1} / ${
												images && images.length
											}`
										"
									></div>

									<figcaption
										:id="`lightbox-description-${index}`"
										v-text="images[index].altText"
									></figcaption>
								</div>
							</figure>
						</TransitionExt>

						<!-- Previous Button -->
						<BaseButton
							:aria-disabled="index === 0"
							aria-label="Se forrige billede"
							:class="[
								'c-block-list-image-gallery-custom__lightbox-button',
								'absolute top-1/2 left-6 >=1024:left-layout-margin w-40 h-40 z-10',
								'transform -translate-y-1/2',
								'border-2 border-white border-opacity-50',
							]"
							dark
							@click="gotoPrevious"
						>
							<template #icon>
								<SvgCaret
									class="transform rotate-90 -translate-x-2"
								/>
							</template>
						</BaseButton>

						<!-- Next Button -->
						<BaseButton
							:aria-disabled="
								images ? index === images.length - 1 : true
							"
							aria-label="Se næste billede"
							:class="[
								'c-block-list-image-gallery-custom__lightbox-button',
								'absolute top-1/2 right-6 >=1024:right-layout-margin w-40 h-40 z-10',
								'transform -translate-y-1/2',
								'border-2 border-white border-opacity-50',
							]"
							dark
							@click="gotoNext"
						>
							<template #icon>
								<SvgCaret
									class="transform -rotate-90 translate-x-2"
								/>
							</template>
						</BaseButton>

						<!-- Close Button -->
						<BaseButton
							class="absolute top-lg right-layout-margin"
							light
							@click="closeLightbox"
						>
							<template #default>
								<span class="flex items-center gap-x-xs">
									<span class="font-semibold">Luk</span>
									<SvgClose
										class="mt-2 w-xs h-xs text-text"
									/>
								</span>
							</template>
						</BaseButton>
					</div>
				</div>
			</Transition>
		</Portal>
	</div>
</template>

<script>
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';
import LongReadTarget from '~/citi-baseline/components/LongRead/LongReadTarget';
import SvgFullScreen from '~/assets/svgs/icon-fullscreen.svg?inline';
import SvgPlus from '~/assets/svgs/icon-plus.svg?inline';
import SvgClose from '~/assets/svgs/icon-close.svg?inline';
import SvgCaret from '~/assets/svgs/icon-caret.svg?inline';

const focusOverlay = process.server
	? null
	: require('~/citi-baseline/assets/js/focus-overlay.js')?.default;

export default {
	name: 'BlockListImageGalleryCustom',

	components: {
		UmbracoImageExt,
		LongReadTarget,
		SvgFullScreen,
		SvgPlus,
		SvgClose,
		SvgCaret,
	},

	inheritAttrs: false,

	props: {
		title: String,
		includeInToc: Boolean,
		images: Array,
	},

	data() {
		return {
			mobile: false,
			overlayActive: false,
			index: 0,

			dimensions: {
				width: 0,
				height: 0,
			},
		};
	},

	computed: {
		renderedImages() {
			return this.images.map((image) => {
				const { width, height } = this.dimensions;
				let renderedWidth = width;
				let renderedHeight =
					(image.height / image.width) * renderedWidth;

				if (renderedHeight > height) {
					renderedHeight = height;
					renderedWidth =
						(image.width / image.height) * renderedHeight;
				}

				return {
					...image,
					renderedWidth,
					renderedHeight,
				};
			});
		},
	},

	mounted() {
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},

	methods: {
		onResize() {
			const { innerWidth } = window;
			this.mobile = innerWidth < 1024;

			let insetX = 128;
			let insetY = 128;

			if (this.$refs?.lightboxContainer) {
				const { lightboxContainer } = this.$refs;
				const { paddingLeft, paddingTop } =
					getComputedStyle(lightboxContainer);

				insetX = parseFloat(paddingLeft);
				insetY = parseFloat(paddingTop);
			}

			this.dimensions = {
				width:
					Math.min(window.innerWidth, 1440) -
					(innerWidth >= 1024 ? insetX : 16) * 2,
				height: window.innerHeight - insetY * 2,
			};
		},

		openLightbox(index) {
			focusOverlay?.setTarget?.(null);
			this.overlayActive = true;
			this.index = Number(index);

			this.$nextTick(() => {
				this.$nextTick(() => {
					this.onResize();
				});
			});
		},

		closeLightbox() {
			focusOverlay?.setTarget?.(null);
			this.overlayActive = false;
		},

		gotoPrevious() {
			this.index !== 0 && this.index--;
		},
		gotoNext() {
			this.index !== this.images?.length - 1 && this.index++;
		},
	},
};
</script>

<style lang="postcss">
/** Preview Images */
.c-block-list-image-gallery-custom__preview-image {
	@apply relative text-left;
}

.c-block-list-image-gallery-custom__preview-icon {
	@apply absolute top-1/2 left-1/2 inline-block;
	@apply transform -translate-x-1/2 -translate-y-1/2 scale-0;
	@apply flex items-center justify-center;
	@apply w-112 h-112 rounded-full bg-primary;
	@apply duration-500 ease-smooth-out opacity-0;
	z-index: 2;
}
.c-block-list-image-gallery-custom__preview-image:after {
	@apply absolute top-1/2 left-1/2;
	@apply transform -translate-x-1/2 -translate-y-1/2 scale-50;
	@apply flex items-center justify-center;
	@apply w-112 h-112 rounded-full bg-primary bg-opacity-50;
	@apply duration-300 ease-smooth-out opacity-0;
	z-index: 1;
	content: '';
}

.c-block-list-image-gallery-custom__preview-icon svg {
	@apply w-32;
}

.c-block-list-image-gallery-custom__preview-image:hover
	> .c-block-list-image-gallery-custom__preview-icon {
	@apply opacity-100  scale-100;
}
.c-block-list-image-gallery-custom__preview-image:hover:after {
	@apply opacity-100  scale-100;
}

/** Lightbox Images */
.c-block-list-image-gallery-custom__lightbox-item {
	padding: calc(
		var(--theme-layout-margin) + var(--theme-layout-gutter) +
			var(--theme-layout-column-of-12)
	);

	@apply py-7xl;
}

/**
	Lightbox transition
*/
.t-block-list-images-gallery-custom__lightbox-enter-active,
.t-block-list-images-gallery-custom__lightbox-leave-active {
	@apply transform;

	transition: 700ms cubic-bezier(0.5, 0.035, 0.19, 1) !important;
}

.t-block-list-images-gallery-custom__lightbox-enter-active > *,
.t-block-list-images-gallery-custom__lightbox-leave-active > * {
	@apply delay-700 duration-700 ease-smooth-out transform;
}

.t-block-list-images-gallery-custom__lightbox-enter-active
	> .c-block-list-image-gallery-custom__lightbox-button,
.t-block-list-images-gallery-custom__lightbox-leave-active
	> .c-block-list-image-gallery-custom__lightbox-button {
	@apply delay-1000 duration-1000 ease-smooth-out transform origin-center;
}

.t-block-list-images-gallery-custom__lightbox-enter,
.t-block-list-images-gallery-custom__lightbox-leave-to {
	@apply opacity-0;
}

.t-block-list-images-gallery-custom__lightbox-enter > *,
.t-block-list-images-gallery-custom__lightbox-leave-to > * {
	transform: scale(1.1);
	opacity: 0;
}

.t-block-list-images-gallery-custom__lightbox-enter
	> .c-block-list-image-gallery-custom__lightbox-button,
.t-block-list-images-gallery-custom__lightbox-leave-to
	> .c-block-list-image-gallery-custom__lightbox-button {
	opacity: 0;
}

.t-block-list-images-gallery-custom__lightbox-enter > *,
.t-block-list-images-gallery-custom__lightbox-leave-to > * {
	@apply scale-75;
}

.t-block-list-images-gallery-custom__lightbox-item-enter-active,
.t-block-list-images-gallery-custom__lightbox-item-leave-active {
	@apply ease-smooth-out;
}

.t-block-list-images-gallery-custom__lightbox-item-enter,
.t-block-list-images-gallery-custom__lightbox-item-leave-to {
	@apply opacity-0;

	/* transform: scale(0.98); */
}
</style>
